<template>
  <div v-if="ready">
    <!-- Header -->
    <b-navbar type="is-primary"
    fixed-top
    :mobile-burger="false"
    class="width-full"
    style="z-index: 39">
      <template #brand>
        <b-navbar-item
        @click="setNav(!expandNav)">
          <div class="level is-flex width-full">
            <b-icon icon="menu"></b-icon>
            <div>
            <h4 class="has-text-weight-bold ml-2">
              {{ appName  }}
            </h4>
            </div>
          </div>
        </b-navbar-item>
      </template>
      <template v-if="patientProfile === userProfile && settings.organization.phone" #end>
        <b-navbar-item tag="div">
            <b-button icon-left="phone" tag="a" :href="`tel: ${settings.organization.phone}`">
              Call Now
            </b-button>
        </b-navbar-item>
    </template>
    </b-navbar>
    <!-- Body -->

    <div class="columns is-marginless height-sans-navbar">
      <div class="column is-narrow height-full custom-sidebar"
      style="position: fixed; z-index: 19;">
        <div style="padding-top: 30px">
          <article class="media mb-2">
            <avatar :username="userName" class="mr-2"></avatar>
            <div class="media-content has-text-weight-bold"
            :class="{ 'is-hidden': !expandNav }">
              {{userName}}
              <p class="is-italic is-size-7"> ({{typeUser.label}}) </p>
            </div>
          </article>
          <div class="my-4" :class="{ 'is-hidden': !expandNav }">
             <b-field>
                <b-input
                  placeholder="Search..."
                  type="search"
                  icon="magnify"
                  disabled>
                </b-input>
              </b-field>
          </div>
          <router-link
            v-for="(item, i) in menu"
            :key="i"
            :to="{ name: item.to }"
            class="button is-flex mb-2"
            :class="{
              'is-primary-light': $route.name === item.to,
              'is-transparent': $route.name !== item.to,
              'is-justify-content-start': expandNav,
              'is-justify-content-center': !expandNav
            }">
            <b-icon :icon="item.icon"></b-icon>
            <span class="has-text-weight-bold"
            :class="{ 'is-hidden': !expandNav }">
              {{item.label}}
            </span>
          </router-link>
          <button
            class="button is-flex is-transparent mb-2"
            :class="{
              'is-justify-content-start': expandNav,
              'is-justify-content-center': !expandNav
            }"
            @click="logout">
            <b-icon icon="logout"></b-icon>
            <span class="has-text-weight-bold"
            :class="{ 'is-hidden': !expandNav }">
              Logout
            </span>
          </button>
        </div>
      </div>
      <div class="column main-content"
      :class="{ 'nav-expanded': expandNav }"
      >
        <b-message v-if="userAuth && userAuth.isAnonymous"
          has-icon
          title="Account not verified"
          type="is-danger"
          aria-close-label="Close message">
          You are currently using anonoymous account.
          Verify your email to make your account permanent
          <br>
          <b-button
            type="is-primary"
            @click="validateEmail"
            class="has-text-weight-bold"
          >
          Verify Email
        </b-button>
        </b-message>
        <div class="pb-20">
          <router-view/>
        </div>
      </div>
    </div>
    <!-- Sticky footer -->
    <footer id="dashboard-footer" class="py-2 width-full has-background-white" v-if="time">
      <div class="columns is-justify-content-space-between is-align-items-center px-2">
        <div class="column is-flex is-align-items-center">
          <b-button v-if="status.available && closingIn > 0"
          size="is-small"
          type="is-success"
          icon-left="check">
            OPEN
          </b-button>
          <b-button v-else
          size="is-small"
          type="is-danger"
          icon-left="close">
            CLOSED
          </b-button>
          <div v-if="status.openToday" class="ml-2">
            <p v-if="openedSince > 0" :class="{'has-text-danger': closingIn < 0 }">
              Close<span v-if="closingIn < 0">d</span><span v-else>s</span>
              <span class="ml-1 has-text-weight-bold">{{ time.close.format('hh:mm a') }}</span>,
              <span v-if="closingIn < 0">{{ time.close.from(time.now) }}</span>
              <span v-else>{{ time.now.to(time.close) }}</span>
            </p>
            <p v-else>
              Opening
              <span class="ml-1 has-text-weight-bold">{{ time.open.format('hh:mm a') }}</span>,
              {{time.now.to(time.open)}}
            </p>
          </div>
          <div v-else class="has-text-danger ml-2">Does not open today</div>
        </div>
        <div class="column has-text-right">
          <h4 class="mr-2">
            <strong>{{ time.now.format('ddd Do MMM, YYYY hh:mm ss a') }}</strong> {{ timezone }}
          </h4>
        </div>
      </div>
    </footer>
  </div>
  <b-loading v-else></b-loading>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters, mapMutations } from 'vuex';
import Avatar from 'vue-avatar';
import { auth } from '@/pluging/firebase';
import menu from '@/config/menu';
import { PROFILS, PATIENT } from '@/config/user';

export default {
  metaInfo: {
    title: 'Dashboard',
  },
  components: {
    Avatar,
  },
  data() {
    return {
      menu: menu,
      patientProfile: PATIENT,
      time: null,
    };
  },
  computed: {
    ...mapGetters({
      userName: 'userName',
      userAuth: 'auth',
      user: 'user',
      expandNav: 'nav',
      ready: 'appReady',
      settings: 'settings',
      userProfile: 'profil',
      status: 'status',
    }),
    appName() {
      return this.settings && this.settings.organization
        ? this.settings.organization.name : process.env.VUE_APP_NAME;
    },
    validateEmailUrl() {
      return this.url(this.$router.resolve({ name: 'ValidateEmail' }).route.fullPath);
    },
    typeUser() {
      if (!this.user) return {};
      return PROFILS.find(profil => profil.value === this.user.profil);
    },
    timezone() {
      if (this.status && this.status.organization) return this.status.organization.timezone;
      return null;
    },
    openedSince() {
      if (!this.time) return null;
      return this.time.now.diff(this.time.open, 'seconds');
    },
    closingIn() {
      if (!this.time) return null;
      return this.time.close.diff(this.time.now, 'seconds');
    },
  },
  methods: {
    ...mapMutations({
      resetStore: 'RESET',
      setNav: 'SET_NAV',
    }),
    async logout() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        await auth.signOut();
        this.resetStore();
        this.$router.push({ name: 'Login' });
        loadingComponent.close();
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger',
        });
        loadingComponent.close();
      }
    },
    async validateEmail() {
      const loadingComponent = this.$buefy.loading.open();
      await auth.sendSignInLinkToEmail(this.user.email, {
        url: this.validateEmailUrl,
        handleCodeInApp: true,
      });
      this.$buefy.toast.open({
        message: 'Check your mailbox to complete the process',
        type: 'is-primary',
      });
      loadingComponent.close();
    },
  },
  mounted() {
    const adjustSideNav = () => {
      this.setNav(window.screen.width > 768);
    };
    adjustSideNav();
    window.addEventListener('resize', adjustSideNav);

    setInterval(() => {
      this.time = {
        now: moment().tz(this.timezone),
        open: moment(this.status.opens).tz(this.timezone),
        close: moment(this.status.closes).tz(this.timezone),
      };
      this.$store.commit('SET_BUSINESS_OPENING', this.openedSince > 0 && this.closingIn > 0);
      // Refresh status immediately closed or opened
      if (this.closingIn === -1 || this.openedSince === 1) this.$store.dispatch('getStatus');
    }, 1000);
  },
};
</script>

<style>
  .main-content {
    padding-left:85px;
  }
  footer#dashboard-footer {
    position: fixed;
    z-index: 19;
    bottom: 0;
  }
  @media (min-width: 768px) {
    .main-content.nav-expanded {
      padding-top: 30px;
      padding-left: 260px;
    }
  }
</style>
