import { ADMIN, DOCTOR, PATIENT } from '@/config/user';
import store from '../store';

const menuAdmin = [
  // {
  //   label: 'Dashboard',
  //   icon: 'view-dashboard',
  //   to: 'Home',
  // },
  {
    label: 'Users',
    icon: 'account-multiple',
    to: 'Users',
  },
  {
    label: 'Appointments',
    icon: 'calendar',
    to: 'Appointment',
  },
  // {
  //   label: 'Notifications',
  //   icon: 'bell',
  //   to: 'Notifications',
  // },
  {
    label: 'Inbound Calls',
    icon: 'phone',
    to: 'InboundCalls',
  },
  {
    label: 'Profile',
    icon: 'account',
    to: 'Profile',
  },
  {
    label: 'Settings',
    icon: 'settings',
    to: 'Settings',
  },
];

const menuDoctorPatient = [
  // {
  //   label: 'Dashboard',
  //   icon: 'view-dashboard',
  //   to: 'Home',
  // },
  {
    label: 'My Appointments',
    icon: 'calendar',
    to: 'Appointment',
  },
  // {
  //   label: 'Notifications',
  //   icon: 'bell',
  //   to: 'Notifications',
  // },
  {
    label: 'Help',
    icon: 'help-circle',
    to: 'Help',
  },
  {
    label: 'Profile',
    icon: 'account',
    to: 'Profile',
  },
];

const getMenu = () => {
  if (store.getters.profil === ADMIN) return menuAdmin;
  if (store.getters.profil === DOCTOR) return menuDoctorPatient;
  if (store.getters.profil === PATIENT) return menuDoctorPatient;
  return [];
};

export default getMenu();
